import React from "react"
import ContactAddressCopy from "../copy/contact_address"
import ContactFormIntroCopy from "../copy/contact_formintro"
import ContactForm from "./contactform"

export default function ContactFull(props){

    return (
    <div id={ props.className ? "contact_home" : "contact" } className={props.className}>
        <div className="contactform_container">
            <ContactFormIntroCopy />
            <ContactForm></ContactForm>
        </div>
        <div className="sidebar">
            <ContactAddressCopy />
        </div>
        <div className="clearfix"></div>
    </div>
    )
}