import React from "react"

class Contactform extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
        }
    }

    onNameChange (event){
        this.setState({ name: event.target.value })
    }

    onEmailChange (event){
        this.setState({email: event.target.value })
    }

    onMessageChange (event){
        this.setState({message: event.target.value})
    }

    handleSubmit(event){
        // send email
        console.log("send email");
        event.preventDefault();
        fetch('https://services.lionstigersandbears.be/sendmail/',{
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
        ).then((response)=>{
        if (response.status === 'success'){
            alert("Message Sent."); 
            this.resetForm()
        }else if(response.status === 'fail'){
            alert("Message failed to send.")
        }
        })

        
    }

    resetForm(){
        this.setState({name: '', email: '', message: ''})
    }

    render(){
        return(
            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input placeholder="Your name" type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input placeholder="Your e-mail address" type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea placeholder="Your message" className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        )
    }
}

export default Contactform;