import React from "react"
import Layout from "../components/layout"
import ContactFull from "../components/contact_full" 
import HeroBanner from "../components/herobanner"
import heroimage from "../assets/images/contact_hero@2x.jpg"
import ContactHeroCopy from "../copy/contact_hero"

export default function Contact() {
  return (
    <Layout className="borderless_mobile" pageTitle="Contact">
      <HeroBanner title="contact hero" heroimage={{publicURL: heroimage}}>
        <ContactHeroCopy />
      </HeroBanner>

      <ContactFull/>
    </Layout>
  )
}